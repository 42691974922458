import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//样式表
import './assets/css/global.css'
import './assets/css/base.css'

//引入element ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入axios
import axios from "axios";
//引入three
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

Vue.prototype.$three = THREE;
Vue.prototype.$OrbitControls = OrbitControls;
Vue.prototype.$GLTFLoader = GLTFLoader;

Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$http = axios

new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
